<template>
    <div class="modal fade" :id="this._section + this._id" ref="confirmModal" tabindex="-1" aria-labelledby="Подтверждение действия" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content rounded-1 p-3">
                <div class="modal-header d-flex align-items-center mb-3 border-0 p-1">
                    <span class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">{{this._title}}</span>
                    <button 
                        type="button" 
                        class="d-flex ms-auto btn-close"
                        data-bs-dismiss="modal"
						aria-label="Close"
						@click="this.notConfirmedAction()"
                    ></button>
                </div>

                <div class="modal-body border-0 p-1 rounded-0">
                    <div v-if="this._section === 'partnerCard' || this._section === 'employeerShortCard'"
						 class="d-flex flex-column">
                        <span class="text-font-secondary mb-3" >{{ this._text }}</span>
                        <span>{{ this._recipient  }}</span>
                    </div>
                    <div v-if="this._section === 'stafferCard'"
						 class="d-flex flex-column">
                        <div class="d-flex align-items-center mb-3">
                            <img :src="require('@/assets/img/user_avatar.png')" alt="Аватар" width="50" height="50" class="me-10">
                            <span>{{ this._recipient  }}</span>
                        </div>
                        
                        <span class="text-font-secondary mb-3" >{{ this._text }}</span>

                        <div class="d-flex flex-column" v-if="this._action === 'blockStaffer'">
                            <InputSelect
                                :inputLabel="'Причина блокировки'"
                                :inputLabelClass="'text-font-secondary mb-3'"
                                :inputId="'requisitions_client-sort'"
                                :inputName="'requisitions_client-sort'"
                                :inputPlaceholder="'Выберите причину'"
                                :inputOptions="{
                                    1: 'Вариант 1'
                                }"
                                :inputClass="'rounded-1 py-3'"
                                :inputWrapClass="''"
                            />
                        </div>
                    </div>
					<div v-else
						 class="d-flex flex-column">
						<span class="text-font-secondary mb-3" >{{ this._text }}</span>
                	</div>
                </div>

                <div class="modal-footer border-0 p-1">
                    <button 
                        type="button" 
                        class="btn btn-primary col-12 rounded-3 m-0 py-3 text-white fs-3" 
                        data-bs-dismiss="modal"
						@click="this.confirmedAction()">
                        {{ this._btnText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputSelect from '@/components/inputs/InputSelect'
	import CommonService from "@/services/CommonService";
    
    export default {
        name: 'ConfirmModal',
        
        components: {
            InputSelect
        },

        props: {
            id:             { type: Number },
            fromSection:    { type: String, default: 'confirmModal' },
            title:          { type: String },
            text:           { type: String },
            recipient:      { type: String },
            btnText:        { type: String },
            action:         { type: String },
			manualTrigger: { type: Boolean, default: false },
        },

		data() {
			return {
				confirmModal: null,
			}
		},
        methods: {
			hideModal() {
				if (!this._manual && this.confirmModal) {
					this.confirmModal.hide();
				}
			},
			showModal() {
				this.hideModal();

				this.confirmModal = CommonService.getModal(this._section + this._id);
				if (!this._manual && this.confirmModal)
					this.confirmModal.show();

			},
			confirmedAction() {
				let data = { action: this._action, confirmed: true, id: this._id };

				CommonService.log('debug', 'confirmedAction()', data);

                this.$emit('confirmed', data)
            },
			notConfirmedAction() {
				let data = { action: this._action, confirmed: false, id: this._id };

				CommonService.log('debug', 'notConfirmedAction()', data);

                this.$emit('notConfirmed', data)
            }
        },
		mounted() {

			let _this = this;
			let modalId = _this._section + _this._id;
			let modal = document.getElementById(modalId);
			if (modal && typeof modal !== "undefined") {

				modal.addEventListener('hidden.bs.modal', function (event) {
					_this._id = null;
					_this._fromSection = null;
					_this._title = null;
					_this._text = null;
					_this._manual = false;
					_this.emitter.emit("global.modalClose", modal);
				});
				modal.addEventListener('shown.bs.modal', function (event) {
					_this.emitter.emit("global.modalShown", modal);
				});
			}
		},
		watch: {
			'fromSection': function(value, oldValue) {
				if (!this._manual) {

					let modalId = value + this._id;
					let oldModalId = oldValue + this._id;
					if (modalId !== oldModalId) {
						this.$nextTick(() => this.showModal());
					} else {
						this.$nextTick(() => this.hideModal());
					}
				}

				return value;
			}
		},
		computed: {
			_id: {
				get() {
					return (typeof this.$props.id !== "undefined") ? this.$props.id : null;
				},
				set(value) {
					if (typeof value == "string")
						value = value.trim();
					else
						value = value.toString().trim();
				}
			},
			_section: {
				get() {
					return (typeof this.$props.fromSection !== "undefined") ? this.$props.fromSection : null;
				},
				set(value) {
					if (typeof value == "string")
						value = value.trim();
					else
						value = value.toString().trim();
				}
			},
			_title: {
				get() {
					return (typeof this.$props.title !== "undefined") ? this.$props.title : null;
				},
				set(value) {
					value = value.trim();
				}
			},
			_text: {
				get() {
					return (typeof this.$props.text !== "undefined") ? this.$props.text : null;
				},
				set(value) {
					value = value.trim();
				}
			},
			_manual: {
				get() {
					return (typeof this.$props.manualTrigger !== "undefined") ? this.$props.manualTrigger : false;
				},
				set(value) {
					value = value.trim();
				}
			},
			_recipient: {
				get() {
					return (typeof this.$props.recipient !== "undefined") ? this.$props.recipient : null;
				},
				set(value) {
					value = value.trim();
				}
			},
			_action: {
				get() {
					return (typeof this.$props.action !== "undefined") ? this.$props.action : null;
				},
				set(value) {
					value = value.trim();
				}
			},
			_btnText: {
				get() {
					return (typeof this.$props.btnText !== "undefined") ? this.$props.btnText : null;
				},
				set(value) {
					value = value.trim();
				}
			},
		},
    }
</script>