<template>
	<div>
		<div v-if="!this.isEmpty(this.variables)" class="container px-0">
			<div v-for="(variable, index) in this.variables" :key="index"
				 class="position-relative p-3 pt-4 rounded-3 bg-warning bg-opacity-10 mb-10"
				 :ref="'variable_' + index">

				<a href="javascript:{}"
				   class="position-absolute top-0 end-0 p-2 mt-2 me-2"
				   @click="this.removeVariable(index)">
					<img :src="require('@/assets/icons/close-icon.svg')"
						 class="d-block"
						 alt="Удалить" width="20" height="20" />
				</a>

				<InputText :inputId="'label_' + index"
						   :inputName="'label_' + index"
						   :ref="'label_' + index"
						   input-label="Название:"
						   :inputValue="this.variables[index].label"
						   inputLabelClass="col text-font-secondary mb-3"
						   inputClass="col rounded-3 p-2"
						   inputWrapClass="row row-cols-md-2 align-items-center mb-20"
						   inputRequired="true"
						   :inputInValid="(!this.isEmpty(this.errors['labels'][index])) ? this.errors['labels'][index] : false"
						   :inputPlaceholder="'Переменная ' + (index+1) + '…'"
						   @setValue="(value) => this.setAndValidate(index, 'label', value)" />

				<InputText :inputId="'name_' + index"
						   :inputName="'name_' + index"
						   :ref="'name_' + index"
						   input-label="Переменная:"
						   :inputValue="this.variables[index].name"
						   inputLabelClass="col text-font-secondary mb-3"
						   inputClass="col rounded-3 p-2"
						   inputWrapClass="row row-cols-md-2 align-items-center mb-20"
						   inputRequired="true"
						   :nputInValid="(!this.isEmpty(this.errors['names'][index])) ? this.errors['names'][index] : false"
						   inputPlaceholder="Только литинские символы (A-z)…"
						   @setValue="(value) => this.setAndValidate(index, 'name', value)" />

				<InputText :inputId="'value_' + index"
						   :inputName="'value_' + index"
						   :ref="'value_' + index"
						   input-label="Значение:"
						   :inputValue="this.variables[index].value"
						   inputLabelClass="col text-font-secondary mb-3"
						   inputClass="col rounded-3 p-2"
						   :inputInValid="(!this.isEmpty(this.errors['values'][index])) ? this.errors['values'][index] : false"
						   inputWrapClass="row row-cols-md-2 align-items-center"
						   inputPlaceholder="Значение по-умолчанию…"
						   @setValue="(value) => this.setAndValidate(index, 'value', value)" />
			</div>
			<a href="javascript:{}"
			   class="btn btn-light-gray border rounded-1 w-100 mb-20"
			   @click="this.saveVariables">
				Сохранить набор
			</a>
		</div>
		<a href="javascript:{}"
		   class="d-flex flex-grow-1 align-items-center me-10 mb-20"
		   @click="this.addVariable()">
			<div class="d-flex align-items-center justify-content-center btn btn-primary btn-lg rounded-circle me-10"
				 style="width: 50px; height: 50px;">
				<img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить переменную" width="14" height="14">
			</div>
			<span class="text-font-secondary font-semi fw-semi">Добавить переменную</span>
		</a>
		<div class="form-text d-flex fs-5">
			<img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: 1px;">
			Добавляйте поля (переменные) интерактивных документов для автоматического заполнения бланков договоров, соглашений и т.п. Такие документы можно отправлять по эл. почте Вашему клиенту или просто вывести на печать.
		</div>
	</div>
</template>

<script>
    import CommonService from "@/services/CommonService";
	import InputText from "@/components/inputs/InputText";
	import api from "@/api";

	export default {
        name: 'InnerFieldsForm',
		components: {InputText},
		props: {
			variablesList: { type: Array },
			sectionId: { type: [Number, String] },
			sourceId: { type: [Number, String] }
        },
        data() {
			return {
				variables: [],
				errors: {
					'labels': [],
					'names': [],
					'values': [],
				},
			}
        },
		methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			addVariable() {
				let index = this.variables.push({
					'id': null,
					'label': null,
					'name': null,
					'value': null,
				}) - 1;
				this.$nextTick(() => {
					this.$refs['variable_' + index].scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						scrollBehavior: 'smooth'
					});
				});
			},
			removeVariable(index) {
				this.variables.splice(index,1);
				this.errors['labels'].splice(index,1);
				this.errors['names'].splice(index,1);
				this.errors['values'].splice(index,1);
				this.$nextTick(() => {
					if (typeof this.$refs['variable_' + (index-1)] !== "undefined") {
						this.$refs['variable_' + (index-1)].scrollIntoView({
							behavior: 'smooth',
							block: 'center',
							scrollBehavior: 'smooth'
						});
					}
				});
			},
			setAndValidate(index, name, value) {

				let valid = true;
				if (name == 'label') {
					let translit = CommonService.transliterate(value, '_');
					this.setAndValidate(index, 'name', translit);
				}

				if (name == 'name') {
					value = CommonService.transliterate(value, '_');
				}

				api.post('/docs/validation?section='+ this.$props.sectionId, {
					variables: this.variables
				})
				.then((response) => {

					CommonService.log('debug', 'setAndValidate()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						if (!response.data.is_valid) {

							if (typeof response.data.errors['titles'] !== "undefined") {
								response.data.errors['titles'].forEach((error, index) => {
									this.errors['labels'][index] = error;
								});
							} else {
								this.errors['labels'][index] = false;
							}

							if (typeof response.data.errors['names'] !== "undefined") {
								response.data.errors['names'].forEach((error, index) => {
									this.errors['names'][index] = error;
								});
							} else {
								this.errors['names'][index] = false;
							}

							if (typeof response.data.errors['defaults'] !== "undefined") {
								response.data.errors['defaults'].forEach((error, index) => {
									this.errors['values'][index] = error;
								});
							} else {
								this.errors['values'][index] = false;
							}

						} else {
							valid = false;
						}
					} else {
						valid = false;
					}

				}).catch(function (error) {

					valid = false;

					CommonService.log('error', 'setAndValidate()::axios', error);

				});

				this.variables[index][name] = value;

			},
			saveVariables() {
				api.put('/docs/variables?section='+ this.$props.sectionId +'&source_id=' + this.$props.sourceId, {
					variables: this.variables
				})
				.then((response) => {

					CommonService.log('debug', 'saveVariables()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						this.variables = [];

						if (typeof response.data.list !== "undefined") {
							this.$emit('addVariables', response.data.list);
						}

					} else {
						console.error('saveVariables()::axios', response.data.errors);
					}

				}).catch(function (error) {

					CommonService.log('error', 'saveVariables()::axios', error);

				});
			},
		}
    }
</script>