<template>
	<div class="d-flex flex-column flex-fill align-content-center my-4">
		<img :src="require('@/assets/icons/empty-box.svg')" :alt="this.$props.message" class="d-flex mx-auto w-25 h-25">
		<h3 class="d-inline-flex align-content-center text-font-secondary fs-3 mx-auto my-3">{{ this.$props.message }}</h3>
	</div>
</template>

<script>
    export default {
        name: "EmptyList",
		props: {
			message: {
				type: String,
				default: 'Ничего не найдено'
			}
		},
        components: {

        }
    };
</script>