<template>
	<div :class="wrapper">
		<div class="form-check" :class="this.class">
			<input class="form-check-input"
				   :class="this.class + ((valid) ? ' is-valid' : ((invalid) ? ' is-invalid' : ''))"
				   type="checkbox"
				   :id="id"
				   :name="name"
				   ref="checkbox"
				   :value="value"
				   :disabled="disabled"
				   :readonly="readonly"
				   :checked="(value > 0) ? true : false"
				   @change="this.$emit('setValue', (this.$refs.checkbox.checked) ? 1 : 0)" />
			<label v-if="label" :for="id" class="form-check-label" :class="this.labelClass">
				{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
			</label>
			<div v-if="valid && typeof valid !== 'boolean'" class="valid-feedback" v-text="valid" />
			<div v-else-if="invalid && typeof invalid !== 'boolean'" class="invalid-feedback" v-text="invalid" />
		</div>
		<div v-if="help" class="form-text" :class="helpClass" v-html="help" />
	</div>
</template>

<script>
export default {
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputName: {type: String},
		inputValue: {type: Number},
		inputClass: {type: String},
		inputWrapClass: {type: String},
		inputHelpText: {type: String},
		inputHelpClass: {type: String},
		inputDisabled: {type: [String, Boolean], default: false},
		inputReadonly: {type: [String, Boolean], default: false},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			label: (typeof this.inputLabel !== "undefined") ? this.inputLabel : '',
			labelClass: (typeof this.inputLabelClass !== "undefined") ? this.inputLabelClass : 'text-secondary',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			//value: (typeof this.inputValue !== "undefined") ? this.inputValue : 0,
			class: (typeof this.inputClass !== "undefined") ? this.inputClass : '',
			wrapper: (typeof this.inputWrapClass !== "undefined") ? this.inputWrapClass : '',
			help: (typeof this.inputHelpText !== "undefined") ? this.inputHelpText : '',
			helpClass: (typeof this.inputHelpClass !== "undefined") ? this.inputHelpClass : '',
			//disabled: (typeof this.inputDisabled !== "undefined" && this.inputDisabled === 'true'),
			//readonly: (typeof this.inputReadonly !== "undefined" && this.inputReadonly === 'true'),
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
		};
	},
	watch: {
		'value': function(val, oldVal) {
			this.validateInput(val);
		}
	},
	methods: {
		validateInput() {
			let checkbox = this.$refs.checkbox;
			if (this.invalid)
				checkbox.setCustomValidity(this.invalid);
			else
				checkbox.setCustomValidity("");
		}
	},
	mounted() {
		this.validateInput();
	},
	computed: {
		value() {
			return this.$props.inputValue
		},
		disabled() {
			return this.$props.inputDisabled
		},
		readonly() {
			return this.$props.inputReadonly
		},
	},
}
</script>