<template>
	<div :ref="'wrapper_'+this.id" :class="wrapper">
		<label v-if="label" :for="id" class="form-label" :class="this.labelClass">
			{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
		</label>
		<div class="d-inline-flex align-items-stretch input-group" :class="group">
			<div v-if="prepend" v-html="prepend" class="input-group-text text-dark-gray border-0 bg-transparent p-0 pe-3" />
			<input :id="id"
				   type="number"
				   class="form-control"
				   :class="[this.class, {
						'is-valid': this.valid,
						'is-invalid': this.invalid,
				   }]"
				   ref="input"
				   :name="name"
				   v-model="value"
				   :min="min"
				   :max="max"
				   :step="step"
				   :placeholder="placeholder"
				   :disabled="disabled"
				   :readonly="readonly"
				   @change="(event) => this.emitValue(event.target.value)" />
			<div v-if="append" v-html="append" class="input-group-text text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3" />
			<div v-if="this.remove" class="input-group-text align-self-start text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3">
				<button type="button"
						aria-label="Удалить"
						class="d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-1"
						style="width: 44px; height: 44px;"
						@click="this.emitRemove">
					<img :src="require('@/assets/icons/delete.svg')" class="d-block" alt="Удалить" width="14" height="14">
				</button>
			</div>
			<div v-if="valid && typeof valid !== 'boolean'" class="valid-feedback" v-text="valid" />
			<div v-else-if="invalid && typeof invalid !== 'boolean'" class="invalid-feedback" v-text="invalid" />
		</div>
		<div v-if="help" class="form-text" :class="helpClass" v-html="help" />
	</div>
</template>


<script>
import CommonService from "@/services/CommonService";

export default {
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputName: {type: String},
		inputValue: {type: Number},
		inputMin: {type: Number},
		inputMax: {type: Number},
		inputStep: {type: Number},
		inputClass: {type: String},
		inputGroupClass: {type: String},
		inputWrapClass: {type: String},
		inputHelpText: {type: String},
		inputHelpClass: {type: String},
		inputPlaceholder: {type: String},
		inputDisabled: {type: [String, Boolean], default: false},
		inputRequired: {type: [String, Boolean], default: false},
		inputReadonly: {type: [String, Boolean], default: false},
		inputPrepend: {type: String},
		inputRemove: {type: [String, Boolean], default: false},
		inputAppend: {type: String},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			//value: (typeof this.inputValue !== "undefined") ? this.inputValue : 0,
			class: (typeof this.inputClass !== "undefined") ? this.inputClass : '',
			group: (typeof this.inputGroupClass !== "undefined") ? this.inputGroupClass : '',
			wrapper: (typeof this.inputWrapClass !== "undefined") ? this.inputWrapClass : '',
			help: (typeof this.inputHelpText !== "undefined") ? this.inputHelpText : '',
			helpClass: (typeof this.inputHelpClass !== "undefined") ? this.inputHelpClass : '',
			placeholder: (typeof this.inputPlaceholder !== "undefined") ? this.inputPlaceholder : '',
			//disabled: (typeof this.inputDisabled !== "undefined" && this.inputDisabled === 'true'),
			//required: (typeof this.inputRequired !== "undefined" && this.inputRequired === 'true'),
			//readonly: (typeof this.inputReadonly !== "undefined" && this.inputReadonly === 'true'),
			prepend: (typeof this.inputPrepend !== "undefined") ? this.inputPrepend : false,
			append: (typeof this.inputAppend !== "undefined") ? this.inputAppend : false,
			remove: (typeof this.inputRemove !== "undefined") ? this.inputRemove : false,
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
		};
	},
	watch: {
		'value': function(val, oldVal) {
			this.validateInput(val);
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		emitValue(value) {
			this.$emit('setValue', (!this.isEmpty(value)) ? Number(value) : null);
		},
		emitRemove() {
			if (this.$emit('removeValue', this.value)) {
				this.$refs['wrapper_'+this.id].remove();
			}
		},
		validateInput(value) {

			// Если значение не совпадает с шагом инпута проверям остаток от деления и приводим к правильному значению
			if (!this.isEmpty(value) && !this.isEmpty(this.$props.inputStep)) {

				if (!isNaN(Number(value)))
					value = Number(value);

				if (value !== this.value && !isNaN(Number(this.$props.inputStep))) {
					value = CommonService.roundTo(value, Number(this.$props.inputStep));
					this.value = value;
					this.emitValue(this.value);
				}
			}

			let input = this.$refs.input;
			if (this.invalid)
				input.setCustomValidity(this.invalid);
			else
				input.setCustomValidity("");
		}
	},
	mounted() {
		this.validateInput();
	},
	computed: {
		value: {
			get() {
				return (typeof this.$props.inputValue !== "undefined") ? this.$props.inputValue : '';
			},
			set(value) {
				value = parseFloat(value);
			}
		},
		label() {
			return this.$props.inputLabel
		},
		labelClass() {
			return this.$props.inputLabelClass
		},
		disabled() {
			return this.$props.inputDisabled
		},
		required() {
			return this.$props.inputRequired
		},
		readonly() {
			return this.$props.inputReadonly
		},
		min() {
			return this.$props.inputMin
		},
		max() {
			return this.$props.inputMax
		},
		step() {
			return this.$props.inputStep
		},
	},
}
</script>