<template>
    <div class="modal fade" id="editImageModal" tabindex="-1" aria-labelledby="Редактировать фото" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen modal-lg modal-dialog-centered">
            <div class="modal-content rounded-1 p-3">
                <div class="modal-header d-flex align-items-center mb-3 border-0 p-1">
                    <span class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">Редактирование фото</span>
                    <button type="button"
							class="d-flex ms-auto btn-close"
							data-bs-dismiss="modal"
							aria-label="Close" />
                </div>

                <div class="modal-body border-0 px-0 rounded-0">
					<cropper class="cropper"
							 ref="cropper"
							 :src="this.image_src"
							 @ready="this.cropperReady"
							 @error="this.cropperError"
							 @change="this.setCrop" />


					<div class="d-flex row row-cols-auto mt-3 align-items-center align-content-between">
						<a href="javascript:{}"
						   class="d-flex col pe-3 me-auto mb-3 align-items-center"
						   title="Обрезать изображение"
						   @click="this.applyCrop">
							<span class="btn btn-outline-secondary bg-light-gray me-10 border border-brd-primary rounded-3"
								  style="padding: 0.75rem 0.95rem;">
								<img :src="require('@/assets/icons/crop-icon.svg')" alt="Обрезать" width="18" height="18">
							</span>
							<span class="text-font-light">Обрезать</span>
						</a>
						<a href="javascript:{}"
						   class="d-flex col pe-3 me-auto mb-3 align-items-center"
						   title="Повернуть изображение"
						   @click="this.applyRotate(90)">
							<span class="btn btn-outline-secondary bg-light-gray me-10 border border-brd-primary rounded-3"
								  style="padding: 0.75rem 0.95rem;">
								<img :src="require('@/assets/icons/rotating-icon.svg')" alt="Повернуть" width="18" height="18">
							</span>
							<span class="text-font-light">Повернуть</span>
						</a>
						<a href="javascript:{}"
						   class="d-flex col pe-3 me-auto mb-3 align-items-center"
						   title="Отразить изображение"
						   @click="this.applyFlip(180, 0)">
							<span class="btn btn-outline-secondary bg-light-gray me-10 border border-brd-primary rounded-3"
								  style="padding: 0.75rem 0.95rem;">
								<img :src="require('@/assets/icons/flip-icon.svg')" alt="Отразить" width="18" height="18">
							</span>
							<span class="text-font-light">Отразить</span>
						</a>
					</div>

					<Switcher inputId="set_is_main"
							  inputName="set_is_main"
							  ref="set_is_main"
							  inputLabel="Сделать обложкой"
							  :inputValue="this.set_is_main"
							  inputClass="px-0 mb-3"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.set_is_main = value" />

                </div>

                <div class="modal-footer row justify-content-center border-0 p-0">
					<div class="row p-0">
						<div class="col col-6">
							<button type="button"
									class="btn w-100 btn-lg btn-light-gray border rounded-1 rounded-3 fs-3"
									data-bs-dismiss="modal">
								Отменить
							</button>
						</div>
						<div class="col col-6">
							<button type="button"
									class="btn w-100 btn-lg btn-primary rounded-3 text-white fs-3"
									data-bs-dismiss="modal"
									@click="applyChanges()">
								Сохранить
							</button>
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import { Cropper } from 'vue-advanced-cropper';
	import 'vue-advanced-cropper/dist/style.css';
	import 'vue-advanced-cropper/dist/theme.bubble.css';
	import CommonService from "@/services/CommonService";
	import Switcher from "@/components/inputs/Switcher";

    export default {
        name: 'EditImageModal',
        components: {
			Switcher,
			Cropper
        },
        props: {

        },
		data() {
			return {
				image_id: null,
				image_src: null,
				file_name: null,
				data: {
					crop: null,
					rotate: null,
					flip: null,
				},
				set_is_main: 0,
			}
		},
		methods: {
			applyFlip(x,y, $event) {
				this.$refs.cropper.flip(x, y);
				this.data.flip = [x, y];

				let data = {
					image_id: this.image_id,
					image_src: this.image_src,
					file_name: this.file_name,
					data: this.data.flip
				};

				this.$emit('applyFlip', data);
				//setTimeout(() => window.dispatchEvent(new Event('resize')), 1000);

				CommonService.log('debug', 'editImageModal():applyFlip', data);

			},
			applyRotate(angle, $event) {

				this.$refs.cropper.rotate(angle);
				this.data.rotate = {
					angle: angle
				};

				let data = {
					image_id: this.image_id,
					image_src: this.image_src,
					file_name: this.file_name,
					data: this.data.rotate
				};

				this.$emit('applyRotate', data);
				//setTimeout(() => window.dispatchEvent(new Event('resize')), 1000);

				CommonService.log('debug', 'editImageModal():applyRotate', data);

			},
			applyCrop() {
				let src = this.data.crop.canvas.toDataURL('image/png');
				this.image_src = src;

				let data = {
					image_id: this.image_id,
					image_src: this.image_src,
					file_name: this.file_name,
					data: this.data.crop
				};

				this.$emit('applyCrop', data);

				CommonService.log('debug', 'editImageModal():applyCrop', data);

			},
			setCrop({ coordinates, canvas }) {

				CommonService.log('debug', 'setProps()', {coords: coordinates, canvas: canvas});

				this.data.crop = {
					coords: coordinates,
					canvas: canvas
				};
			},
			cropperReady(event) {

				CommonService.log('debug', 'editImageModal():cropperReady', event);

				setTimeout(() => window.dispatchEvent(new Event('resize')), 1000);
			},
			cropperError(event) {

				CommonService.log('debug', 'editImageModal():cropperError', event);

				window.dispatchEvent(new Event('resize'));
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			getAspectRatio(src_width, src_height, max_width, max_height) {
				let ratio = Math.min(max_width / src_width, max_height / src_height);
				return { width: src_width * ratio, height: src_height * ratio };
			},
			applyChanges() {

				let src = this.data.crop.canvas.toDataURL('image/png');
				this.image_src = src;

				if (process.env.NODE_ENV == "development")
					console.debug('editImageModal():applyChanges', {
						image_id: this.image_id,
						image_src: this.image_src,
						file_name: this.file_name,
						is_main: this.set_is_main,
						data: this.data
					});

				this.$emit('applyChanges', {
					image_id: this.image_id,
					image_src: this.image_src,
					file_name: this.file_name,
					is_main: this.set_is_main,
					data: this.data
				});
			},
		},
		mounted() {
			let modal = document.getElementById('editImageModal');
			if (modal && typeof modal !== "undefined") {

				let _this = this;
				modal.addEventListener('hidden.bs.modal', function (event) {
					_this.image_id = null;
					_this.image_src = null;
					_this.file_name = null;
					_this.set_is_main = 0;
					_this.emitter.emit("global.modalClose", modal);
				});

				modal.addEventListener('shown.bs.modal', function (event) {

					let target = event.relatedTarget
					if (typeof target !== "undefined") {
						_this.image_id = target.getAttribute('data-bs-image-id');
						_this.image_src = target.getAttribute('data-bs-image-src');
						_this.file_name = target.getAttribute('data-bs-file-name');
					}

					// ***

					if (process.env.NODE_ENV == "development") {
						console.debug('editImageModal::show.bs.modal', {
							image_id: _this.image_id,
							image_src: _this.image_src,
							file_name: _this.file_name,
							set_is_main: _this.set_is_main
						});
					}
					_this.emitter.emit("global.modalShown", modal);
				});
			}
		}
	}
</script>

<style lang="scss">
	/*$base-color	white
	$grid	false
	$grid-color	$base-color
	$handler-color	$base-color
	$line-color	$base-color
	$handler-size	14px
	$hover-handler-size	25px*/
	.cropper {
		height: 360px;
		width: 100%;
		background: #DDD;
	}
</style>