<template>
	<div>
		<div class="modal fade" :id="this.successModalId" ref="successResultModal" tabindex="-1" aria-labelledby="Запрос выполнен" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header d-flex flex-column border-0 p-1">
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body d-flex flex-column align-items-center p-1">
						<span class="d-inline-flex bg-primary rounded-circle p-4" style="width: 5rem; height: 5rem;">
							<img :src="require('@/assets/icons/checked.svg')" alt="..." width="32" height="32">
						</span>
						<span class="modal-title fs-2 fw-semi font-semi lh-1 mt-3" v-text="this.message_title ?? 'OK'" />
						<span class="text-center mb-3 text-secondary mt-3" v-html="this.message_text ?? 'Операция выполнена успешно!'" />
					</div>
					<div class="modal-footer border-0 p-1">
						<button v-if="this.success_button" type="button"
								class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
								data-bs-dismiss="modal"
								@click="this.success_button_action"
								:aria-label="this.success_button">
							{{ this.success_button }}
						</button>
						<button v-if="this.second_success_button" type="button"
								class="btn btn-outline-secondary col-12 rounded-3 py-3 fs-3"
								data-bs-dismiss="modal"
								@click="this.second_success_button_action"
								:aria-label="this.second_success_button">
							{{ this.second_success_button }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" :id="this.errorModalId" ref="errorResultModal" tabindex="-1" aria-labelledby="Ошибка выполнения запроса" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header d-flex flex-column border-0 p-1">
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body d-flex flex-column align-items-center p-1">
						<span class="d-inline-flex bg-danger rounded-circle p-4" style="width: 5rem; height: 5rem;">
							<img :src="require('@/assets/icons/removed.svg')" alt="..." width="32" height="32">
						</span>
						<span class="modal-title fs-2 fw-semi font-semi lh-1 mt-3" v-text="this.message_title ?? 'Ошибка!'" />
						<span class="text-center mb-3 text-secondary mt-3" v-html="this.message_text ?? 'Попробуйте позже или обратитесь в поддержку.'" />
					</div>
					<div class="modal-footer border-0 p-1">
						<button type="button"
								class="btn btn-dark-gray col-12 rounded-3 py-3 text-white fs-3"
								data-bs-dismiss="modal"
								aria-label="Close">
							Закрыть
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import CommonService from "@/services/CommonService";
import * as bootstrap from 'bootstrap';

export default {
	name: 'ResultsModal',
	props: {
		id: { type: [String, Number]},
		state: { type: String },
		title: { type: String },
		message: { type: String },
		manualTrigger: { type: Boolean, default: false },
		successButton: { type: String },
		successAction: { type: Function },
		secondSuccessButton: { type: String },
		secondSuccessAction: { type: Function }
	},
	data() {
		return {
			success_button: (typeof this.$props.successButton !== "undefined") ? this.$props.successButton : 'Ok',
			success_button_action: (typeof this.$props.successAction !== "undefined") ? this.$props.successAction : null,
			second_success_button: (typeof this.$props.secondSuccessButton !== "undefined") ? this.$props.secondSuccessButton : null,
			second_success_button_action: (typeof this.$props.secondSuccessAction !== "undefined") ? this.$props.secondSuccessAction : null,
			successModal: null,
			successModalId: (typeof this.$props.id !== "undefined") ? 'successResultModal_' + this.$props.id : 'successResultModal',
			errorModal: null,
			errorModalId: (typeof this.$props.id !== "undefined") ? 'errorResultModal_' + this.$props.id : 'errorResultModal',
			manual: (typeof this.$props.manualTrigger !== "undefined") ? this.$props.manualTrigger : false,
		}
	},
	methods: {
		hideSuccessModal() {
			if (!this.manual && this.successModal) {
				this.successModal.hide();
				//this.successModal.dispose();
			}
		},
		hideErrorModal() {
			if (!this.manual && this.errorModal) {
				this.errorModal.hide();
				//this.errorModal.dispose();
			}
		},
		showSuccessModal() {
			this.hideAllModals();

			this.successModal = CommonService.getModal('successResultModal_' + this.id);
			if (!this.manual && this.successModal)
				this.successModal.show();

		},
		showErrorModal() {
			this.hideAllModals();

			this.errorModal = CommonService.getModal('errorResultModal_' + this.id);
			if (!this.manual && this.errorModal)
				this.errorModal.show();

		},
		hideAllModals() {
			this.message_state = null;
			this.message_title = null;
			this.message_text = null;
			this.hideErrorModal();
			this.hideSuccessModal();
		}
	},
	watch: {
		'current_state': function(value, oldValue) {
			if (!this.manual) {
				console.info('watch::current_state', {value, oldValue});
				if (value === 'success') {
					this.showSuccessModal();
					//this.$nextTick(() => this.showSuccessModal());
				} else if (value === 'error') {
					this.$nextTick(() => this.showErrorModal());
				} else {
					this.$nextTick(() => this.hideAllModals());
				}
			}

			return value;
		}
	},
	computed: {
		message_title: {
			get() {
				return (typeof this.$props.title !== "undefined") ? this.$props.title : null;
			},
			set(value) {
				value = (value) ? value : null;
			}
		},
		message_state: {
			get() {
				return (typeof this.$props.state !== "undefined") ? this.$props.state : null;
			},
			set(value) {
				value = (value) ? value : null;
			}
		},
		message_text: {
			get() {
				return (typeof this.$props.message !== "undefined") ? this.$props.message : null;
			},
			set(value) {
				value = (value) ? value : null;
			}
		},
		current_state: function () {
			return this.message_state ?? null;
		}
	},
}
</script>