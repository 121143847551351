<template>
	<div class="modal fade" id="geoLocationModal" tabindex="-1" aria-labelledby="Уточнить координаты объекта" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-3">
				<div class="modal-header d-flex mb-20 border-0 p-1">
					<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Координаты объекта
					</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column p-1"
					 :data-geo-zoom="this.zoom">
					<yandex-map v-if="!this.isEmpty(this.coords)"
								:settings="this.settings"
								:coords="this.coords"
								:zoom="this.zoom"
								ref="ymap"
								ymap-class="yandex-map">
						<ymap-marker v-if="!this.isEmpty(this.coords)"
									 :coords="this.coords"
									 marker-id="ymapMarker"
									 ref="ymarker"
									 :options="{
										draggable: true
									 }"
									 :icon="this.markerIcon"
									 hint-content="Двигайте маркер для уточнения адреса"
									 @dragend="this.changeCoords($event)" />
					</yandex-map>
				</div>

				<div class="modal-footer border-0 p-1">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						data-bs-dismiss="modal"
						@click="this.saveCoords()"
					>
						Закрыть
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import CommonService from "@/services/CommonService";

export default {
	props: {
		mapMarkerLat: {type: [ Number, String ]},
		mapMarkerLng: {type: [ Number, String ]},
	},
	data() {
		return {
			coords: [],
			settings: {
				apiKey: this.$root.config.yandexMap.apiKey,
				lang: this.$root.config.yandexMap.lang,
				coordorder: this.$root.config.yandexMap.coordorder,
				enterprise: this.$root.config.yandexMap.enterprise,
				version: this.$root.config.yandexMap.version
			},
			markerIcon: {
				layout: 'default#image',
				imageHref: require('@/assets/svg/map-pin.svg'),
				imageSize: [33, 36],
				imageOffset: [-14, -36],
				contentOffset: [0, 15],
				contentLayout: '<div>$[properties.iconContent]</div>'
			},
			zoom: 18,
			new_coords: []
		};
	},
	components: { yandexMap, ymapMarker },
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		formatGeo(data) {

			let coord = data;

			if (!isNaN(parseFloat(data)))
				coord = parseFloat(data);

			console.log(coord);
			return coord;
		},
		/*getCoords() {
			let coords = this.coords;
			CommonService.log('debug', 'getCoords()', {coords: coords});

			console.log(coords);
			return coords;
		},*/
		setCoords() {

			if (typeof coords !== "undefined")
				this.coords = coords;

			let coords = this.coords;
			CommonService.log('debug', 'setCoords()', {coords: coords});
		},
		changeCoords(event) {

			if (typeof event.originalEvent.target.geometry !== "undefined")
				this.new_coords = event.originalEvent.target.geometry._coordinates;

			let coords = this.new_coords;
			CommonService.log('debug', 'changeCoords()', {event, new_coords: coords});

			this.$emit('changeCoords', { lat: coords[0], lng: coords[1] });
		},
		saveCoords() {

			let coords = this.coords;
			if (typeof this.new_coords !== "undefined" && this.new_coords.length)
				coords = this.new_coords;

			CommonService.log('debug', 'saveCoords()', {new_coords: coords});

			this.$emit('saveCoords', { lat: coords[0].toPrecision(9), lng: coords[1].toPrecision(9) });
		},
	},
	mounted() {
		var _this = this;
		var mapModal = document.getElementById('geoLocationModal');

		mapModal.addEventListener('show.bs.modal', function (event) {
			let target = event.relatedTarget

			let lat = _this.lat;
			if (!_this.isEmpty(target.getAttribute('data-bs-lat')))
				lat = _this.formatGeo(target.getAttribute('data-bs-lat')) ?? _this.lat;

			let lng = _this.lng;
			if (!_this.isEmpty(target.getAttribute('data-bs-lng')))
				lng = _this.formatGeo(target.getAttribute('data-bs-lng')) ?? _this.lng;

			let coords = [lat, lng];
			_this.zoom = 18;
			_this.coords = coords;

			_this.$nextTick(() => {
				_this.zoom = 18;
				_this.coords = coords;
			});

			CommonService.log('debug', 'geoLocationModal::show.bs.modal', {target, coords});

		});

	},
	watch: {
		/*'coords': function(val, oldVal) {
			this.$nextTick(() => {
				this.zoom = 18;
				this.coords = val;
			});
		}*/
	},
	computed: {
		lat() {
			return (typeof this.mapMarkerLat !== "undefined" && !isNaN(parseFloat(this.mapMarkerLat)))
				? this.formatGeo(this.mapMarkerLat)
				: this.formatGeo(this.$root.config.yandexMap.coords[0]);
		},
		lng() {
			return (typeof this.mapMarkerLng !== "undefined" && !isNaN(parseFloat(this.mapMarkerLng)))
				? this.formatGeo(this.mapMarkerLng)
				: this.formatGeo(this.$root.config.yandexMap.coords[1]);
		},
		/*coords: {
			get() {
				return [
					this.formatGeo(this.lat) ?? this.formatGeo(this.$root.config.yandexMap.coords[0]),
					this.formatGeo(this.lng) ?? this.formatGeo(this.$root.config.yandexMap.coords[1])
				];
			},
			set(value) {
				value = new Set(value);
			}
		},*/
	},
}
</script>

<style lang="scss">
.modal {
	.modal-body {
		.yandex-map {
			width: 100%;
			min-height: 240px;
		}
	}
}
</style>